
export function navbar() {
    var menuheight = $("header").outerHeight();
    $(".banner-top").css("margin-top", (menuheight-1)+"px")
    console.log(menuheight)
    $(document).ready(function () {
        

        $(document).on("click", ".filter-menu li", function (event) {
            $("[data-tags]").hide();
            if ($(this).hasClass("active")) {
                $(this).removeClass("active");
            } else {
                $(".filter-menu li").removeClass("active");
                $(this).addClass("active");
            }
            var activeItems = $(".filter-menu li.active");
            var tag = activeItems.find("button").data("value");
            if(tag != undefined) {
                $( "[data-tags*='"+tag+"']" ).fadeIn(500);
            } else {
                $("[data-tags]").fadeIn();
            }
            if(activeItems.length == 0) {
                $(".filter-menu li").first().addClass("active");
            }
        });

        $(document).on("click", ".info button", function (event) {
            var openText = $(this).data("open");
            var closeText = $(this).data("close");
            if($(this).hasClass("open")) {
                console.log("F")
                $(this).parent().find(".info-wrapper").slideUp(0, function () {
                    $(this).css('display', 'none');
                });
                $(this).html(openText);
                $(this).removeClass("open");
            } else {
                $(this).html(closeText);

                $(this).addClass("open");
                $(this).parent().find(".info-wrapper").slideDown(0, function () {
                    $(this).css('display', 'flex');
                });
            }
        });


        $(document).on("click", ".has-children", function (event) {
            event.preventDefault();
            var menuId = $(this).data("id");
            var menuItem = $("[data-menu='" + menuId + "']");
            if (menuItem.hasClass("show")) {
                menuItem.removeClass("show");
            } else {
                $(".subMenu").removeClass("show");
                menuItem.addClass("show");
                if ($(window).width() < 960) {
                    var height = $("header").outerHeight();
                    console.log(height)
                    menuItem.css("padding-top", height);
                }
            }
        });

        $(document).on("click", ".close-menu", function (event) {
            $(".menu").removeClass("active");
        });
        $(document).on("click", ".close-submenu", function (event) {
            $(".subMenu").removeClass("show");
        });
        $(document).on("click", ".mobile-menu", function (event) {
            var height = $("header").outerHeight();
            console.log(height)
            $(".menu").css("padding-top", height);
            $(".menu").addClass("active");
        });
        var index = undefined;
        $(document).on("click", ".product-gallery img", function () {
            var imageUrl = $(this).data("imagesrc");
            index = $(this).parent().index();
            var overlay = $(this).closest(".product-gallery").find(".overlay");
            overlay.addClass("active");
            overlay.find("img").attr("src", imageUrl);
        });
        $(document).on("click", ".close", function () {
            var overlay = $(".product-gallery").find(".overlay");
            overlay.removeClass("active");
        });
        $(document).on("click", ".image-control button", function () {
            var imageListLength = $(".product-gallery").find("li").length;
            var overlayImage = $(".product-gallery").find(".overlay").find("img");
            if ($(this).hasClass("next")) {
                if (index >= (imageListLength - 1)) {
                    index = 0;

                } else {
                    index++;
                }
            } else {
                if (index == 0) {
                    index = (imageListLength - 1);
                } else {
                    index--;
                }
            }
            var nextImage = $(".product-gallery").find("li:eq(" + index + ")").find("img");
            overlayImage.attr("src", nextImage.data("imagesrc"))
        });
    });
}

export default navbar;